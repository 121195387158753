*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
  
html {
    box-sizing: border-box;
    font-size: 62.5%;
}
@media screen and (max-width: 600px) {
    html {
        font-size: 50%;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(254, 248, 237);
}
