.Quiz--container {
    max-width: 50rem;
    min-height: 45rem;
    margin: -25rem auto 0 auto;
    padding: 4rem;
    border: 1px solid grey;
    background-color: #fff;
    box-shadow: 0rem 1rem 4rem 0rem rgba(0,0,0,1);
}

.Quiz--question {
    font-size: 3rem;
    font-weight: bold;
}

.Quiz--answers {
    padding: 4rem;
}

.Quiz--answer {
    display: flex;
    align-items: center;
    font-size: 2rem;
    padding: 1rem;
}
.Quiz--answer::before {
    content: "";
    width: 2rem;
    height: 2rem;
    border: .3rem solid rgb(0, 0, 0);
    margin-right: 1rem;
}
.Quiz--answer.selected.correct::before {
    background-color: rgb(94, 181, 94);
}
.Quiz--answer.selected.incorrect::before {
    background-color: rgb(215, 61, 61);
}

.Quiz--answer_checkbox {
    display: none;
}