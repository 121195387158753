.Error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width: 50rem;
    min-height: 45rem;
    margin: -25rem auto 0 auto;
    padding: 4rem;
    border: 1px solid grey;
    background-color: #fff;
    box-shadow: 0rem 1rem 4rem 0rem rgba(0,0,0,1);
}

.Error-message {
    font-size: 3rem;
}

.Error-comment {
    font-size: 2rem;
}