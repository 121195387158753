.App--header {
    display: flex;
    justify-content: center;
    background-image: url('../images/cantina.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 40rem;
    box-shadow: inset 0rem -1rem 4rem 2rem #000;
    border-bottom: 3rem solid rgb(166, 181, 199);
}

.App--title {
    font-size: 6rem;
    text-shadow: 0 0 4rem #000;
    color: white;
    margin: 4rem 0 0 0;
}

@media (max-width: 600px) {
    .App--title {
        font-size: 4rem;
    }
}