.Result--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 50rem;
    min-height: 45rem;
    margin: -25rem auto 0 auto;
    padding: 4rem 4rem 5rem 4rem;
    border: 1px solid grey;
    background-color: #fff;
    box-shadow: 0rem 1rem 4rem 0rem rgba(0,0,0,1);
}

.Result--score {
    font-size: 4rem;
}

.Result--level {
    color: #fff;
    text-shadow: 0 0 1rem #000;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 30rem;
    height: 20rem;
    padding: 3rem;
    font-size: 3rem;
    font-weight: bold;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid gray;
}
.Result--level-JarJar {
    background-image: url('../images/jarjar.jpg');
}
.Result--level-Ewok {
    background-image: url('../images/ewok.jpg');
}
.Result--level-C3PO {
    background-image: url('../images/c3po.jpg');
}
.Result--level-ObiWan {
    background-image: url('../images/obiwan.jpg');
}
.Result--level-Yoda {
    background-image: url('../images/yoda.jpg');
}

.Results--button-retry {
    padding: 1.5rem;
    font-size: 2rem;
    cursor: pointer;
    border: 1px solid gray;
}
.Results--button-retry:hover {
    background-color: #fff;
}